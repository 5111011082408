import { useMessage } from "@/hooks/web/useMessage";
import type { AnyObject } from "ant-design-vue/es/_util/type";
import { isArray } from "lodash-es";
import { pinyin } from 'pinyin-pro';
import dayjs, { Dayjs } from 'dayjs'

export type FormatType = 'yyyy-mm-dd' | 'yyyy-mm-dd HH:mm:ss' |'YYYY-MM-DD hh:mm:ss'|'YYYY-MM-DD HH:mm:ss'| 'yyyy-mm-dd HH:mm' |'YYYY-MM-DD HH:mm'| 'mm-dd' | 'MM.DD' | 'MM-DD' | 'dd' | 'yyyy-mm-dd'| 'YYYY-MM-DD' | 'yyyy-mm' | 'YYYY-MM' |undefined
/**将事件格式化为yyyy-mm-dd格式 */
export const  formatDate = (value:Date|number|string|null|undefined ,type: FormatType='yyyy-mm-dd',datesymbol?: string,timesymbol?: string|undefined) => {
    const date = value ? new Date(value) : new Date()
    const dateSymbol = datesymbol || '-'
    const timeSymbol = timesymbol || ':'
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString(); // getMonth() 是从0开始的，所以要加1
    var day = date.getDate().toString();
    var hour = date.getHours().toString().padStart(2, '0');
    var min = date.getMinutes().toString().padStart(2, '0');
    var s = date.getSeconds().toString().padStart(2, '0');
    month = month.padStart(2, '0'); // 如果月份小于10，前面补0
    day = day.padStart(2, '0'); // 如果日期小于10，前面补0
    if (type === 'yyyy-mm-dd'||type === 'YYYY-MM-DD' ||type === 'dd') {
        return `${year +  dateSymbol + month +  dateSymbol + day}`
    } else if(type === 'mm-dd'|| type === 'MM.DD'||type === 'MM-DD') {
        return `${month + dateSymbol + day}`;
    } else if (type === 'yyyy-mm-dd HH:mm:ss'||type === 'YYYY-MM-DD hh:mm:ss'|| type === 'YYYY-MM-DD HH:mm:ss') {
        return `${year + dateSymbol + month + dateSymbol + day} ${hour + timeSymbol + min + timeSymbol + s}`
    } else if (type === 'yyyy-mm-dd HH:mm'||type === 'YYYY-MM-DD HH:mm') {
        return `${year + dateSymbol + month + dateSymbol + day} ${hour + timeSymbol + min}`
    } else if (type === 'yyyy-mm' || type === 'YYYY-MM') {
        return `${year + dateSymbol + month} `
    }else {
        return day
    }
}

/**
 * 格式化金额
 * @param price 要格式化的金额(单位分)
 * @param decimal 最多保留的小数位数
 * @param unit 单位 元/千/万  默认元
 */
export const formatMoney = (price: number | string | null | undefined, decimal:number = 2, unit: 'yuan' | 'qian' | 'wan' = 'yuan'):string => {
    let money: number | string = Number(price || 0)
    if (unit === 'yuan') {
        money = (money / 100)
    } else if (unit === 'qian') {
        money = (money / 100000)
    } else if (unit === 'wan') {
        money = (money / 1000000)
    }
    if ((`${money}`).includes('.')) {
        money = Number(Number(money).toFixed(decimal)).toLocaleString()
    } else {
        money = money.toLocaleString()
    }
    
    return `${money}`
}

interface Item<T, K, J> {
    /** labelName */
    label: T;
    /** value */
    value: K;
    /** 树节点子节点 */
    children?: Item<T, K, J>[];
}

interface Option<T, K, J> {
    /** 树节点 labelKeyName */
    labelKey?: T;
    /** 树节点 valueKeyName */
    valueKey?: K;
    /** 树节点子节点 childrenKeyName */
    childrenKey?: J;
    /** 是否返回所有label，包含父级label */
    isAllLabel?: boolean;
}

/**
 * 获取树节点中与给定value对应的label
 * 
 * @param data 树节点数据
 * @param value 目标值
 * @param option 配置
 * @param option.labelKey 对应的labelKey
 * @param option.valueKey  对应的valueKey
 * @param option.childrenKey  对应的childrenKey
 * @param option.isAllLabel  是否返回所有label，包含父级label
 * @returns 对应的label或包含父级label的数组
 */
export const getLabelName = <T, K, J>(data: Item<T, K, J>[], value: K, option?: Option<T, K, J>): string | string[] => {
    const labelKey = option?.labelKey || 'label';
    const valueKey = option?.valueKey || 'value';
    const childrenKey = option?.childrenKey || 'children';

    const findLabel = (nodes: Item<T, K, J>[], targetValue: K, path: string[]): string | string[] | null => {
        for (const node of nodes) {
            const nodeLabel = node[labelKey as keyof Item<T, K, J>] as unknown as string;
            const nodeValue = node[valueKey as keyof Item<T, K, J>];
            const children = node[childrenKey as keyof Item<T, K, J>] as unknown as Item<T, K, J>[];

            if (nodeValue === targetValue) {
                return option?.isAllLabel ? [...path, nodeLabel] : nodeLabel;
            }

            if (children) {
                const result = findLabel(children, targetValue, [...path, nodeLabel]);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    };

    const result = findLabel(data, value, []);
    return result ? result : option?.isAllLabel ? [] : '';
}

/**复制文本 */
export const copyText = (copyText:string,successTips?:string) => {
    const { createMessage } = useMessage()
    const tempElement = document.createElement("textarea");
    tempElement.value = copyText;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
    createMessage.success(successTips || '复制成功')
}

/**
 * 根据图片地址获取图片宽高
 *  
 *@param url  图片地址src
 * @returns 图片宽高对象
*/
export const getImgWH = (url:string) => {
    return new Promise<void>((resolve:any) => {
        let img = new Image()
        img.src = url
        img.onload = function(){
            let imgWH = {
                width:img.width,
                height:img.height
            }
            resolve(imgWH)
        }; 
    })
}
/**
 * 将YYYY-MM-DD hh:mm:ss转化为时分秒00:00:00和23:59:59 
 * 
 * @param timeString 时间字符串
 * @param type 1：'00:00:00' 2：23:59:59
 * @returns 转化后的时间字符串
 */
/***/
export const conversionTime = (timeString:string,type?:number) => {
    // console.log(timeString,'timeString',type,'type');
    if(timeString&&type === 1) return timeString.slice(0,11)+'00:00:00'
    if(timeString&&type === 2) return timeString.slice(0,11)+'23:59:59'
}

/**
 * 处理请求表格查询时的startTime参数
 */
export const handleBeforeFetchTime = (params:AnyObject) => {
    if (params.hasOwnProperty('startTime') && isArray(params.startTime)) {
        params.endTime = conversionTime(params.startTime[1] || '', 2)
        params.startTime = conversionTime(params.startTime[0] || '', 1)
    }
}

//组装时间范围
export const range = (start: number, end: number) => {
    const result:number[] = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
}

//过期时间禁用当前时间和之后的时间 （年月日）
export const disabledDate = (current: Dayjs) => {
    return current && current < dayjs().subtract(1, 'day').endOf('day')
}
//过期时间禁用当前时间和之后的时间 （时分 不禁用秒）
export const disabledDateTime = (dates: any,day?:any) => {
    const hours = day? dayjs(day).hour():dayjs().hour()
    const minutes = day? dayjs(day).minute(): dayjs().minute()
    console.log(hours,minutes,'999')
    if (!dates) {
        dates = dayjs()
    }
    if (dates && dayjs(dates).date() === dayjs().date()) {
        if (dayjs(dates).hour() === dayjs().hour()) {
            return {
                disabledHours: () => range(0, hours),
                disabledMinutes: () => range(0, minutes)
            }
        } else {
            return {
                disabledHours: () => range(0, hours),
                disabledMinutes: () => []
            }
        }
    } else {
        return {
            disabledHours: () => [],
            disabledMinutes: () => []
            }
    }
}

/**
 * 根据文件名截取文件名后缀
 * @param fileNameString test.jpg
 * @returns 字符串后缀 .jpg
 */
 export const cutOutFileName = (fileNameString:string) => {
    const pos = fileNameString.lastIndexOf(".");
    return fileNameString.substring(pos,fileNameString.length)
}

/**
 * 将中文符号转换成英文符号
 */ 
export function chineseChar2englishChar(chineseChar){
    // 将单引号‘’都转换成'，将双引号“”都转换成"
    var str = chineseChar.replace(/\’|\‘/g,"'").replace(/\“|\”/g,"\"");
    // 将中括号【】转换成[]，将大括号｛｝转换成{}
    str = str.replace(/\【/g,"[").replace(/\】/g,"]").replace(/\｛/g,"{").replace(/\｝/g,"}");
    // 将逗号，转换成,，将：转换成:
    str = str.replace(/，/g,",").replace(/：/g,":");
    return str;
}

/**
 * 将中文符字符转换成英文符号
 */ 
 export function chinesetoUpperCase(chineseString:string){ 
    if(chineseString) {
        const UpperCaseArr = pinyin(chineseString, { toneType: 'none', type: 'array' }) || []
        return UpperCaseArr?.join(' ')?.toUpperCase()
    }
}

/* 数组对象根据属性值去重 */
export function removeRepeat(tempArr:any,key:string) {
    let newArr:any = [];
    for (let i = 0; i < tempArr.length; i++) {
        if (newArr.indexOf(tempArr[i][key]) == -1) {
            newArr.push(tempArr[i][key]);
        } else {
            tempArr.splice(i, 1);
            i--;
        };
    };
    return tempArr;

}